'use strict'
angular
	.module('cb.contract-console-controller', [])
	.controller('ContractConsoleController', ["$scope", "$rootScope", "AppointmentService", "EnquiryModalService", "NotificationService", function ($scope, $rootScope, AppointmentService, EnquiryModalService, NotificationService) {

		var hash = (string, max) => {
			var hash = 0;
			for (var i = 0; i < string.length; i++) {
				hash += string.charCodeAt(i);
			}
			return hash % max;
		};

		let HashTable = function () {

			let storage = [];
			const storageLimit = 14;

			this.print = function () {
				console.log(storage)
			}

			this.add = function (key, value) {
				var index = hash(key, storageLimit);
				if (storage[index] === undefined) {
					storage[index] = [
						[key, value]
					];
				} else {
					var inserted = false;
					for (var i = 0; i < storage[index].length; i++) {
						if (storage[index][i][0] === key) {
							storage[index][i][1] = value;
							inserted = true;
						}
					}
					if (inserted === false) {
						storage[index].push([key, value]);
					}
				}
			};

			this.remove = function (key) {
				var index = hash(key, storageLimit);
				if (storage[index].length === 1 && storage[index][0][0] === key) {
					delete storage[index];
				} else {
					for (var i = 0; i < storage[index].length; i++) {
						if (storage[index][i][0] === key) {
							delete storage[index][i];
						}
					}
				}
			};

			this.lookup = function (key) {
				var index = hash(key, storageLimit);
				if (storage[index] === undefined) {
					return undefined;
				} else {
					for (var i = 0; i < storage[index].length; i++) {
						if (storage[index][i][0] === key) {
							return storage[index][i][1];
						}
					}
				}
			};

		};

		

		$scope.Height = 500;

		$scope.contractMarkers = {};
		$scope.contractConsole = { Filter: "All", Fitter: "" };
		$scope.contractConsoleDrpStart = moment().subtract(1, 'months');
		$scope.contractConsoleDrpEnd = moment();
		$scope.contractConsoleDrp_options = {
			timePicker: false,
			opens: 'left',
			startDate: $scope.contractConsoleDrpStart,
			endDate: $scope.contractConsoleDrpEnd,
			format: 'DD/MM/YYYY',
			showDropdowns: true
		};

		$scope.contractConsoleDisplayStartDate = "";
		$scope.contractConsoleDisplayEndDate = "";

		$scope.fitterMapFilterOptions = [
			'All',
			'SoldSurvey',
			'SurveyBooked',
			'InstallBooked',
			'Remakes',
			'ServiceCalls',
			'CharagableServiceCalls',
			'MaintenanceVisit',
			'PhoneCall'
		];

		$scope.$watchCollection('[contractConsoleDrpStart, contractConsoleDrpEnd]', function () {
			$scope.contractConsoleDisplayStartDate = moment($scope.contractConsoleDrpStart).local().format('DD/MM/YYYY');
			$scope.contractConsoleDisplayEndDate = moment($scope.contractConsoleDrpEnd).local().format('DD/MM/YYYY');
		});

		$scope.init = function () {
			$scope.contractConsole = { Filter: "All", Fitters: [] };
			$scope.contractConsoleDrpStart = moment().subtract(1, 'months');
			$scope.contractConsoleDrpEnd = moment();
			$scope.contractConsoleDrp_options = {
				timePicker: false,
				opens: 'left',
				startDate: $scope.contractConsoleDrpStart,
				endDate: $scope.contractConsoleDrpEnd,
				format: 'DD/MM/YYYY',
				showDropdowns: true
			};

			$scope.getStaff();
			$scope.initMap();
		};

		$scope.initMap = function () {
			angular.extend($scope, {
				center: {
					lat: 51.505,
					lng: -0.09,
					zoom: 10
				},
				defaults: {
					tileLayer: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
					scrollWheelZoom: false,
					maxZoom: 20
				}
			});
		};

		$scope.getStaff = function () {
			$scope.GettingStaffListPromise = EnquiryModalService.getStaff();
			$scope.GettingStaffListPromise.then(function (output) {
				$scope.StaffList = output.data.StaffList;
				$scope.FittersList = output.data.StaffList.filter(f => f.Roles.filter(r => r.Name.indexOf("Fitter") != -1).length > 0);
				$scope.NonrecipientList = output.data.StaffList;
			}, function (error) {
				NotificationService.alert('Staff Error', 'Failed to retrieve the list of staff.', 'error', {});
			})
		}

		$scope.contractConsoleSearch = function () {		
			var data = { Filter: $scope.contractConsole.Filter, FitterIds: $scope.contractConsole.Fitters, Start: $scope.contractConsoleDrpStart, End: $scope.contractConsoleDrpEnd };
			$scope.ht = new HashTable();
			$scope.lngindex = 0;
			$scope.latindex = 0;
			$scope.colourIndex = 0;
			$scope.contractMarkers = {};
			$scope.GetContractConsoleAppointmentsPromise = AppointmentService.GetContractConsoleAppointments(data);
			$scope.GetContractConsoleAppointmentsPromise.then((res) => {
				console.log("Result:", res);
				if (res.data.Data) {
					//alert(JSON.stringify(res.data.Data));
					
					res.data.Data.forEach((item) => {

						if (!(item.Address.Lat && item.Address.Lng)) {

							// Align across bottom 
							if (!item.Address.Lat)
							{
								item.Address.Lat = 48.43078905 + $scope.latindex;
							}

							if (!item.Address.Lng)
							{
								item.Address.Lng = -0.919681799999999 + $scope.lngindex;
							}

							$scope.lngindex = $scope.lngindex - 0.75;

							if ($scope.lngindex <= - 10) {
								$scope.lngindex = 0;
								$scope.latindex = $scope.latindex - 0.75;
							}
						}

						if (item.Address.Lat && item.Address.Lng) {
							$scope.contractMarkers[item.Id] = {
								lat: parseFloat(item.Address.Lat),
								lng: parseFloat(item.Address.Lng),
								focus: false,
								message: "<dt>Name: " + item.LeadName + "</dt>"
									+ "<dt>Address : " + item.Address.PrimaryStreet + "</dt>"
									+ "<dt>Town/City : " + item.Address.Town + "</dt>"
									+ "<dt>County : " + item.Address.County + "</dt>"
									+ "<dt>Postcode: " + item.Address.Postcode + "</dt>"
									+ "<dt>Phone Number: " + (item.PhoneNumber || "") + "</dt>"
									+ "<dt>Job: " + item.Job + "</dt>"
									+ "<dt>Job Booked: " + (item.Date || "") + "</dt>"
									+ "<dt>Fitter: " + (item.Fitter || "") + "</dt>"
									+ "<dt>Order: <a href='#/sales-order/" + item.LeadProfileId + "' target='_blank'>" + item.LeadProfileId + "</a>" + "</dt>",
								icon: {
									iconSize: [25, 41],
									iconAnchor: [12, 41],
									popupAnchor: [1, -34],
									iconUrl: getFitterMarkerIcon(item)
								}
							};
						}
					});
				}
				console.log("Markers: ", $scope.contractMarkers);
			}, (err) => {
				console.log("Error:", err);
			});
		};

		function getFitterMarkerIcon(item) {

			if (item.Job == "Sold (To Survey)" && (item.Fitter == undefined || item.Fitter == ""))
				return "assets/img/fittermarkers/sold-no-fitter.png";
			else {
				$scope.colour = "";

				//alert('ft :' + item.Fitter);
				//alert('lk :' + $scope.ht.lookup(item.Fitter));

				// Fitter hash table.	
				if ($scope.ht.lookup(item.Fitter) == undefined) {
					$scope.ht.add(item.Fitter, $scope.colourIndex);
					$scope.colour = $scope.colourIndex;
					$scope.colourIndex++;
					//alert('i');
				}
				else {
					$scope.colour = $scope.ht.lookup(item.Fitter);
					//alert('e');
				}		

				return getColour($scope.colour);
			}
		}

		function getColour(index) {
			//alert('gc: ' + index);
			switch (index) {
				case 0:
					return "assets/img/fittermarkers/00.png";
				case 1:
					return "assets/img/fittermarkers/01.png";
				case 2:
					return "assets/img/fittermarkers/02.png";
				case 3:
					return "assets/img/fittermarkers/03.png";
				case 4:
					return "assets/img/fittermarkers/04.png";
				case 5:
					return "assets/img/fittermarkers/05.png";
				case 6:
					return "assets/img/fittermarkers/06.png";
				case 7:
					return "assets/img/fittermarkers/07.png";
				case 8:
					return "assets/img/fittermarkers/08.png";
				case 9:
					return "assets/img/fittermarkers/09.png";
				case 10:
					return "assets/img/fittermarkers/10.png";
				case 11:
					return "assets/img/fittermarkers/11.png";
				case 12:
					return "assets/img/fittermarkers/12.png";
				case 13:
					return "assets/img/fittermarkers/13.png";
				case 14:
					return "assets/img/fittermarkers/14.png";
				case 15:
					return "assets/img/fittermarkers/15.png";
				case 16:
					return "assets/img/fittermarkers/16.png";
				case 17:
					return "assets/img/fittermarkers/17.png";
				case 18:
					return "assets/img/fittermarkers/18.png";
				case 19:
					return "assets/img/fittermarkers/19.png";
				case 20:
					return "assets/img/fittermarkers/20.png";
				case 21:
					return "assets/img/fittermarkers/21.png";
				case 22:
					return "assets/img/fittermarkers/22.png";
				case 23:
					return "assets/img/fittermarkers/23.png";
				case 24:
					return "assets/img/fittermarkers/24.png";
				case 25:
					return "assets/img/fittermarkers/25.png";
				case 26:
					return "assets/img/fittermarkers/26.png";
				case 27:
					return "assets/img/fittermarkers/27.png";
				case 28:
					return "assets/img/fittermarkers/28.png";
				case 29:
					return "assets/img/chargeable-marker.png";
				case 30:
					return "assets/img/maintenance-marker.png";
				case 31:
					return "assets/img/courtesy-marker.png";
				case 32:
					return "assets/img/survey-marker.png";
				case 33:
					return "assets/img/survey-marker.png";
				case 34:
					return "assets/img/installation-marker.png";
				case 35:
					return "assets/img/fittermarkers/1.png";
				case 36:
					return "assets/img/Service-marker.png";
				case 37:
					return "assets/img/chargeable-marker.png";
				case 38:
					return "assets/img/maintenance-marker.png";
				case 39:
					return "assets/img/courtesy-marker.png";
				case 40:
					return "assets/img/survey-marker.png";
				case 41:
					return "assets/img/survey-marker.png";
				case 42:
					return "assets/img/installation-marker.png";
				case 43:
					return "assets/img/fittermarkers/2.png";
				case 44:
					return "assets/img/Service-marker.png";
				case 45:
					return "assets/img/chargeable-marker.png";
				case 46:
					return "assets/img/maintenance-marker.png";
				case 47:
					return "assets/img/courtesy-marker.png";
				case 48:
					return "assets/img/survey-marker.png";
				case 49:
					return "assets/img/survey-marker.png";
				case 50:
					return "assets/img/installation-marker.png";
				case 51:
					return "assets/img/fittermarkers/3.png";
				case 52:
					return "assets/img/Service-marker.png";
				case 53:
					return "assets/img/chargeable-marker.png";
				case 54:
					return "assets/img/maintenance-marker.png";
				case 55:
					return "assets/img/courtesy-marker.png";
				case 56:
					return "assets/img/survey-marker.png";
				case 57:
					return "assets/img/survey-marker.png";
				case 58:
					return "assets/img/installation-marker.png";
				case 59:
					return "assets/img/fittermarkers/4.png";
				case 60:
					return "assets/img/Service-marker.png";
				case 61:
					return "assets/img/chargeable-marker.png";
				case 62:
					return "assets/img/maintenance-marker.png";
				default:
					return "assets/img/spare-marker3.png";
			}

		}

		$scope.formatFilterOption = function (string) {
			return string.replace(/([A-Z])/g, ' $1').trim();
		}

		$scope.changeHeight = function (change) {
			$scope.Height += change;
		}
	}])
